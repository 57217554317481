import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faCar, faHome, faQuestion, faTimes, faPhone, faSignOutAlt, faInfoCircle, faFileSignature, faInr } from '@fortawesome/free-solid-svg-icons';
import { AppConstants } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';

interface SideMenu {
  path: string;
  label: string;
  icon: any;
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss',],
})
export class SideNavComponent {

  faTimes = faTimes;
  faSignOutAlt = faSignOutAlt;
  faInr = faInr;
  faFileSignature = faFileSignature;

  navWidth: number = AppConstants.ZERO;
  menus: SideMenu[] = [
    {
      path: '',
      label: 'Home',
      icon: faHome,
    },
    {
      path: 'cars',
      label: 'Browse Cars',
      icon: faCar,
    },
    {
      path: 'corporate-lease',
      label: 'Corporate Lease',
      icon: faFileSignature,
    },
    {
      path: 'ppp-invest',
      label: 'PPP Invest',
      icon: faInr,
    },
    // {
    //   path: 'dashboard/profile',
    //   label: 'Profile',
    //   icon: faUser,
    // },
    // {
    //   path: 'cars/shortlists',
    //   label: 'Wishlist',
    //   icon: faHeart,
    // },
    {
      path: 'about-us',
      label: 'About Us',
      icon: faInfoCircle,
    },
    {
      path: 'faqs',
      label: 'FAQs',
      icon: faQuestion,
    },
    {
      path: 'contact-us',
      label: 'Get In Touch',
      icon: faPhone,
    },
  ];

  constructor(private _router: Router, private _authService: AuthService) { }


  toggleNav() {
    this.navWidth = this.navWidth === AppConstants.ZERO ? AppConstants.NAV_WIDTH : AppConstants.ZERO;
  }

  navigateTo(menu: SideMenu) {
    this._router.navigateByUrl(`${menu.path}`);
    this.toggleNav();
  }

  logout() {
    this._authService.logout().subscribe({
      next: () => {
        this._router.navigateByUrl('');
      },
    });
  }
}
