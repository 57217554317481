<header *ngIf="showHeader" [ngClass]="fixedHeader ? 'tw-sticky tw-top-0' : ''" class="tw-p-4 tw-w-full tw-shadow-sm tw-bg-white"
  style="z-index: 8;">
  <div class="container tw-flex tw-items-center tw-px-0 tw-gap-4 md:tw-gap-5">
    <span *ngIf="smallScreen" class="tw-mr-4">
      <button aria-label="Open Side Navigation" (click)="openSideNav()" class="tw-text-lg  tw-p-0">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
    </span>
    <app-logo></app-logo>
    <!-- Divider -->
    <div *ngIf="!smallScreen" class="tw-ml-2 tw-bg-grayLight" style="width: 2px; height: 40px;"></div>
    <span *ngIf="smallScreen" class="spacer"></span>
    <app-search-mobile *ngIf="smallScreen"></app-search-mobile>
    <app-filter-dropdown class="tw-flex-1" *ngIf="!smallScreen"></app-filter-dropdown>

    <p aria-label="PumPumPum Corporate Lease" *ngIf="!smallScreen" class="tw-pr-2 tw-font-semibold tw-cursor-pointer tw-text-base hover:tw-text-pinkDark tw-duration-300 hover:tw-scale-110" (click)="navigateTo('corporate-lease')">Corporate Lease</p>

    <!-- <p aria-label="PumPumPum Investment" *ngIf="!smallScreen"
      class="tw-pr-2 tw-font-semibold hover:tw-text-[#008000]  tw-cursor-pointer hover:tw-border-yellow hover:tw-scale-110 tw-duration-300"
      (click)="navigateTo('ppp-invest')">
      PPP Invest
    </p> -->

    <p aria-label="View FAQ" *ngIf="!smallScreen"
      class="tw-font-semibold hover:tw-text-pinkDark hover:tw-scale-110 hover:tw-cursor-pointer tw-duration-300" (click)="navigateTo('faqs')">
      FAQ
    </p>

    <button *ngIf="!smallScreen"
      class="tw-bg-grayUltraLight tw-w-8 tw-h-8 tw-rounded-full hover:tw-scale-110 tw-duration-300">
      <a aria-label="Call PumPumPum" href="tel:+91 8178050450" class="hover:tw-text-pinkDark "><fa-icon
          [icon]="faPhone"></fa-icon></a></button>

    <button aria-label="View Profile" class="tw-bg-grayUltraLight tw-w-8 tw-h-8 tw-rounded-full hover:tw-scale-110 tw-duration-300"
      (click)="openLoginDialog()">
      <fa-icon [icon]="faUser" class="hover:tw-text-pinkDark"></fa-icon>
    </button>
  </div>
</header>
<app-side-nav></app-side-nav>