import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root',
})
export class SEOService {

  constructor(
    private _title: Title,
    private _meta: Meta,
    @Inject(DOCUMENT) private _doc: any
  ) { }

  updateTitle(title: string) {
    this._title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this._meta.updateTag({ name: 'og:url', content: url, });
  }

  updateDescription(desc: string) {
    this._meta.updateTag({ name: 'description', content: desc, });
  }

  _destroyLinkForCanonicalURL() {
    const els = this._doc.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  setCanonicalLink(url: string) {
    this._destroyLinkForCanonicalURL();
    const link: HTMLLinkElement = this._doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._doc.head.appendChild(link);
    link.setAttribute('href', url);
  }

  setNoIndex() {
    this._meta.updateTag({ name: 'robots', content: 'noindex', });
  }
}