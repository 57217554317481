import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _location: Location
  ) {
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkIsLogin();
  }

  checkIsLogin(): boolean {

    if (this._authService.getToken()) return true;

    // Navigate to the login page with extras
    this._router.navigateByUrl(`auth?next=${this._location.path()}`);
    return false;
  }
}