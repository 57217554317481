<div id="mySidenav" class="sidenav tw-flex" (click)="toggleNav()" [style.width]="navWidth + '%'">
  <div class="sidenav-content" (click)="$event.stopPropagation()">
    <div class="d-flex p-4 align-items-center">
      <app-logo></app-logo>
    </div>
    <ul>
      <li class="tw-whitespace-nowrap tw-flex tw-justify-start tw-items-center" *ngFor="let menu of menus;"
        (click)="navigateTo(menu)">
        <div class="tw-w-4 tw-text-center"> <fa-icon [icon]="menu.icon" class="tw-text-lg tw-text-center"></fa-icon>
        </div>
        <div class="tw-ml-6">{{menu.label}}</div>
      </li>
      <!-- <li class="tw-whitespace-nowrap tw-flex tw-justify-start tw-items-center tw-mt-24">
        <div class="tw-w-4 tw-text-center"> <fa-icon [icon]="faSignOutAlt" class="tw-text-lg tw-text-center"></fa-icon></div>
        <div class="tw-ml-6" (click)="logout()">Logout</div>
      </li> -->
    </ul>
  </div>
  <div class="tw-w-24 tw-flex tw-items-start tw-justify-center">
    <button aria-label="Toggle Side Navigation" class="btn" (click)="toggleNav(); $event.stopPropagation();">
      <fa-icon class="tw-text-white tw-text-xl tw-mt-2" [icon]="faTimes"></fa-icon>
    </button>
  </div>
</div>