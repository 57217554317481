<footer>
  <div class="container tw-text-xs  md:tw-text-sm tw-font-semibold">
    <div class="row tw-pt-6 md:tw-pt-12 tw-text-center md:tw-text-left">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="col-12 tw-w-fit tw-mx-auto md:tw-w-full">
          <app-logo></app-logo>
        </div>
        <div class="col-12">
          <p class="tw-py-6 ">PumPumPum is a flexible, affordable
            <br> and convenient solution for car ownership.
          </p>
        </div>
        <div class="tw-flex tw-flex-col">
          <div class="tw-pb-2">
            <fa-icon [icon]=faPhone class="tw-pr-4"></fa-icon>
            <a aria-label="Call PumPumPum" href="tel:+91 8178050450">+91 8178050450</a>
          </div>
          <div class="tw-pb-4">
            <fa-icon [icon]=faEnvelope class="tw-pr-4"></fa-icon>
            <a aria-label="Email PumPumPum" href="mailto:support@pumpumpum.com">support@pumpumpum.com</a>
          </div>
        </div>
        <div class="tw-flex tw-gap-4 md:tw-justify-start tw-justify-center">
          <a aria-label="View PumPumPum on facebook" target="_blank" href="https://www.facebook.com/pumpumpumcars/">
            <fa-icon class="hover:tw-text-[#1877F2]" [icon]=faFacebook></fa-icon>
          </a>

          <a aria-label="View PumPumPum on instagram" target="_blank" href="https://www.instagram.com/pumpumpum_cars/">
            <fa-icon class="hover:tw-text-[#E4405F]" [icon]=faInstagram></fa-icon>
          </a>

          <a aria-label="View PumPumPum on twitter" target="_blank" href="https://twitter.com/pumpumpum_car">
            <fa-icon class="hover:tw-text-[#1DA1F2]" [icon]=faTwitter></fa-icon>
          </a>

          <a aria-label="View PumPumPum on linkedin" target="_blank" href="https://in.linkedin.com/company/pumpumpum">
            <fa-icon class="hover:tw-text-[#0A66C2]" [icon]=faLinkedin></fa-icon>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <ul>
          <li class="tw-font-extrabold">GET IN TOUCH</li>
          <li>
            <a aria-label="About PumPumPum" href="/about-us">About Us</a>
          </li>
          <li>
            <a aria-label="Contact Us" href="/contact-us">Contact Us</a>
          </li>
          <li>
            <a aria-label="View FAQ's" href="/faqs">Retail FAQs</a>
          </li>
          <li>
            <a aria-label="View FAQ's" href="/corporate-lease#bottom">Corporate FAQs</a>
          </li>
          <!-- <li (click)="navigateTo('smart-loan')">SmartLoan</li> -->
          <li><a aria-label="View PumpPumPum blogs" target="_blank" href="/discover/">Blog</a></li>
        </ul>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <ul>
          <li class="tw-font-extrabold">CAR SUBSCRIPTION</li>
          <li>
            <a aria-label="View cars in Delhi NCR" href="/city/delhi-ncr-car-subscription">Delhi NCR</a>
          </li>
          <li>
            <a aria-label="View cars in Bangalore" href="/city/bangalore-car-subscription">Bangalore</a>
          </li>
          <li>
            <a aria-label="View cars in Hyderabad" href="/city/hyderabad-car-subscription">Hyderabad</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <ul>
          <li class="tw-font-extrabold">USEFUL LINKS</li>
          <li>
            <a aria-label="Browse Cars" href="/cars"> Browse Cars</a>
          </li>
          <li>
            <a aria-label="Corporate Lease" href="/corporate-lease"> Corporate Lease</a>
          </li>
          <!-- <li>
            <a aria-label="Terms & Conditions" href="/ppp-invest">PPP Invest</a>
          </li> -->
          <li>
            <a aria-label="Privacy Policy" href="/privacy-policy"> Privacy Policy</a>
          </li>
          <li>
            <a aria-label="Terms & Conditions" href="/terms-and-conditions"> Terms & Conditions</a>
          </li>
          <!-- <li (click)="navigateTo('smart-loan-terms-and-condition')">Terms & Conditions <small>(SmartLoan)</small></li> -->
          <!-- <li (click)="navigateTo('refund-policy')">Refund Policy</li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="container md:tw-text-left tw-text-center">
      <p class="tw-text-[10px]">© 2023 PumPumPum.com</p>
    </div>
  </div>
</footer>