/* eslint-disable no-magic-numbers */
import { AppConstants } from './constants';
import { Tenure } from './models/car.model';

export const validateRegex = (regex: RegExp, input: string) => regex.test(input);

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === AppConstants.ZERO) return '0 Bytes';
  const k = 1024;
  const dm = decimals < AppConstants.ZERO ? AppConstants.ZERO : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB',];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const bytesToMegaBytes = (sizeInBytes: number) => parseFloat((sizeInBytes / (1024 * 1024)).toFixed(2));

export const calculateLeaseRental = (tenure: number, mileage: number, tenures: Tenure[]): number => {
  const tIndex: number = tenures.findIndex(t => t.tenure === tenure);
  const m = tenures[tIndex].mileageOptions.find(m => m.mileage === mileage);
  return m ? m.displayPrice : AppConstants.ZERO;
};

export const removeUtmParams = (params: any) => {
  const paramsIncluded = { ...params, };
  const paramsToExclude: string[] = ['gclid',];
  Object.keys(params).forEach((key) => {
    if (key.startsWith('utm') || paramsToExclude.includes(key)) delete paramsIncluded[key];
  });
  return paramsIncluded;
};

// https://stackoverflow.com/a/40517249
export const removeNullAndUndefinedFromObject = (object: object) => JSON.parse(JSON.stringify(object, (_, value) => value ?? undefined));

export const convertUrlToStr = (url: string = '') => {
  return url.split('-').join(' ');
};

export const convertStrToUrl = (url: string = '') => {
  return url.split(' ').join('-');
};