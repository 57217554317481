import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ListedCar } from 'src/app/core/models/car.model';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss',],
})
export class SearchResultComponent implements OnChanges {

  faChevronRight = faChevronRight;
  @Input() cars: ListedCar[] = [];
  @Output() itemClick = new EventEmitter<any>();

  brands: string[] = [];
  models: string[] = [];
  variants: any[] = [];

  constructor() { }

  ngOnChanges(_changes: SimpleChanges): void {
    this.brands = [];
    this.models = [];
    this.variants = [];
    this.cars.forEach((car: ListedCar) => {
      if (!this.brands.includes(car.brand.name)) {
        this.brands.push(car.brand.name);
      }
      if (!this.models.includes(car.model.name)) {
        this.models.push(car.model.name);
      }
      if (!this.variants.includes(car.variant.name)) {
        this.variants.push({ name: car.variant.name, id: car.variant._id, });
      }
    });
  }

  selectBrand(brand: string) {
    this.itemClick.emit({ brand, });
  }

  selectModel(model: string) {
    this.itemClick.emit({ model, });
  }

  selectVariant(variant: any) {
    if (!variant) return;
    const [{ slug, _id, },] = this.cars.filter(i => i.variant._id === variant._id);
    this.itemClick.emit({ variant: variant.name, slug: slug + '-' + _id, });
  }
}
