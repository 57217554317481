import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppConstants } from './core/constants';
import { GoogleAnalyticsGTagComponent } from './google-analytics-gtag';
import { NitroXComponent } from './nitro-x';

@NgModule({
  declarations: [
    AppComponent,
    NitroXComponent,
    GoogleAnalyticsGTagComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp', }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: false,
    }),
    ModalModule.forRoot(),
    ...environment.production ? [
      NgxGoogleAnalyticsModule.forRoot(AppConstants.GOOGLE_A_ID),
      NgxGoogleAnalyticsRouterModule,
    ] : [],
  ],
  providers: [],
  bootstrap: [AppComponent,],
})
export class AppModule { }
