import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { AppConstants } from './core/constants';

@Component({
  selector: 'app-google-analytics-gtag',
  template: '',
})
export class GoogleAnalyticsGTagComponent {

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: Object,
    @Inject(DOCUMENT) private _doc: Document
  ) {
    // BROWSER
    if (environment.production) {
      /**
       * Global site tag (gtag.js) - Google Analytics
       */

      // Commented reason : NgxGoogleAnalyticsModule will add the same
      // const script = this._doc.createElement('script') as HTMLScriptElement;
      // script.src = `https://www.googletagmanager.com/gtag/js?id=${AppConstants.GOOGLE_A_ID}`;
      // script.async = true;
      // this._doc.head.appendChild(script);

      const scriptGA = this._doc.createElement('script') as HTMLScriptElement;
      scriptGA.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', '${AppConstants.GOOGLE_A_ID}');
      `;
      this._doc.head.appendChild(scriptGA);

      /**
       * Google Tag Manager
       */
      const scriptGTM = this._doc.createElement('script') as HTMLScriptElement;
      scriptGTM.text = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", '${AppConstants.GOOGLE_GTM_ID}');
      `;
      this._doc.head.appendChild(scriptGTM);

      /**
       * Meta Pixel Code
       */
      const scriptMetaPixel = this._doc.createElement('script') as HTMLScriptElement;
      scriptMetaPixel.textContent = `
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '671090410081961');
        fbq('track', 'PageView');
      `;
      this._doc.head.appendChild(scriptMetaPixel);

      const noScriptMetaPixel = this._doc.createElement('noscript') as HTMLScriptElement;
      noScriptMetaPixel.textContent = `
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=671090410081961&ev=PageView&noscript=1" />
      `;
      this._doc.head.appendChild(noScriptMetaPixel);

      /**
       * LinkedIn Tag
       */
      const scriptLinkedIn = this._doc.createElement('script') as HTMLScriptElement;
      scriptLinkedIn.textContent = `
        _linkedin_partner_id = "4515793";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function (l) {
          if (!l) {
            window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
            window.lintrk.q = []
          }
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript"; b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `;
      this._doc.head.appendChild(scriptLinkedIn);

      const noScriptLinkedIn = this._doc.createElement('noscript') as HTMLScriptElement;
      noScriptLinkedIn.textContent = `
        <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4515793&fmt=gif" />
      `;
      this._doc.head.appendChild(noScriptLinkedIn);

      /**-
       * Hotjar Tracking Code for https://pumpumpum.com/
       */
      // const scriptHotjar = this._doc.createElement('script') as HTMLScriptElement;
      // scriptHotjar.text = `
      //   (function (h, o, t, j, a, r) {
      //     h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      //     h._hjSettings = { hjid: 3503221, hjsv: 6 };
      //     a = o.getElementsByTagName('head')[0];
      //     r = o.createElement('script'); r.async = 1;
      //     r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      //     a.appendChild(r);
      //   })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      // `;
      // this._doc.head.appendChild(scriptHotjar);

      /**
       * Global site tag (gtag.js) - Google Ads
       */
      const scriptAW = this._doc.createElement('script') as HTMLScriptElement;
      scriptAW.src = `https://www.googletagmanager.com/gtag/js?id=${AppConstants.GOOGLE_GTM_ID}`;
      scriptAW.async = true;
      this._doc.head.appendChild(scriptAW);

      const scriptAW2 = this._doc.createElement('script') as HTMLScriptElement;
      scriptAW2.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
    
        gtag("config", '${AppConstants.GOOGLE_AD_ID}');
      `;
      this._doc.head.appendChild(scriptAW2);

      const scriptAW3 = this._doc.createElement('script') as HTMLScriptElement;
      scriptAW3.text = `
        if (window.location.href.indexOf("/kyc") != -1) {
          gtag("event", "conversion", {
            send_to: "${AppConstants.GOOGLE_AD_ID}/lrYuCJGEjtADEMip1r4C",
          });
        }
    
        if (window.location.href.indexOf("/dashboard") != -1) {
          gtag("event", "conversion", {
            send_to: "${AppConstants.GOOGLE_AD_ID}/28YVCNvn3c8DEMip1r4C",
          });
        }
    
        if (window.location.href.indexOf('/kyc/bank-statement-status') != -1) {
          gtag('event', 'conversion', {
            'send_to': '${AppConstants.GOOGLE_AD_ID}/XFt2CJzA5IgYEMip1r4C'
          });
        }
    
        window.addEventListener("load", function () {
          if (window.location.href.indexOf("/contact-us") != -1) {
            var x = 0;
            var timer = setInterval(function () {
              if (document.querySelectorAll(".toast-success").length > 0) {
                if (x == 0) {
                  gtag("event", "conversion", {
                    send_to: "${AppConstants.GOOGLE_AD_ID}/8o7wCNDN3c8DEMip1r4C",
                  });
                  x = 1;
                }
                clearInterval(timer);
              }
            });
          }
        });
      `;
      this._doc.head.appendChild(scriptAW3);
    } else {
      // const scriptClarity = this._doc.createElement('script') as HTMLScriptElement;
      // const dev = `
      //   (function (c, l, a, r, i, t, y) {
      //     c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      //     t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      //     y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      //   })(window, document, "clarity", "script", "h8cdyn6st3");
      // `;
      // const staging = `
      //   (function(c,l,a,r,i,t,y){
      //       c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      //       t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      //       y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      //   })(window, document, "clarity", "script", "h8cc2ph50f");
      // `;
      // scriptClarity.text = environment.domain.includes('dev') ? dev : staging;
      // this._doc.head.appendChild(scriptClarity);
    }
  }
}