<div class="dashboard">
  <div class="shadow-sm tw-bg-white tw-flex tw-items-center tw-p-4 dashboard-header">
    <button class="tw-text-black tw-py-1 tw-mr-4" (click)="navigateBack()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>
    <app-logo></app-logo>
  </div>
  <div class="container dashboard-content mb-3">
    <div class="row g-4">
      <div class="bg-white tab-container md:tw-py-2"
        [ngClass]="{'col-md-auto' : !isSmallScreen, 'fixed-tab': isSmallScreen, 'desktop-tab': !isSmallScreen}">
        <app-tab-button *ngFor="let tab of tabs; let i = index;" [ngClass]="{'active': tab.isActive, 'hide': !tab.isVisible}"
          (onClick)="navigateTo(tab.path, i)" [label]="tab.label" [icon]="tab.icon">
        </app-tab-button>
      </div>
      <div class="col" [ngClass]="{'p-0': isSmallScreen}">
        <!-- <div class="" style="padding: 0%; min-height: 70vh;"> -->
          <router-outlet></router-outlet>
        <!-- </div> -->
        <!-- <app-no-cost-emi-bank-offer></app-no-cost-emi-bank-offer> -->
      </div>
    </div>
  </div>
  <div class="bg-white">
    <app-footer></app-footer>
  </div>
</div>