import { isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss',],
})
export class AppComponent {

  constructor(
    private _router: Router,
    private _$gaService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private readonly _platformId: Object
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && isPlatformBrowser(this._platformId) && environment.production) {
        this._$gaService.event('page_change', 'page_view', event.urlAfterRedirects);
      }
    });
  }
}
