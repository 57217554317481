import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/response.model';
import { map } from 'rxjs/operators';
import { ListedCar, CarList, CarShortlisted } from '../models/car.model';
import { AppConstants } from '../constants';
import { InventoryCar } from '../models/car.inventory.model';
import { removeUtmParams } from '../utils';
import { BrandList } from '../models/brand.model';
import { ModelList } from '../models/model.model';
import { VariantList } from '../models/variant.model';


@Injectable({
  providedIn: 'root',
})
export class CarService {

  private _carEndpoint = `${environment.apiBaseUrl}/vehicle/v1`;

  constructor(private _http: HttpClient) { }

  getListedCars(params: any): Observable<CarList> {
    if (params && params['page']) {
      params = {
        ...params,
        offset: params['page'],
        limit: AppConstants.PER_PAGE,
      };
      delete params['page'];
    }
    // Always send currently selected city
    const cityName = localStorage.getItem(AppConstants.CURRENT_CITY_KEY);
    if (cityName) params = { ...params, city: cityName.replace('-', ' '), };
    // Override if not pumpumpum
    if (environment.source.toLowerCase() != 'pumpumpum') {
      params = { ...params, brand: environment.source, };
    }
    params = removeUtmParams(params);

    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/vehicles', { params, })
      .pipe(map((res) => res.data));
  }

  // eslint-disable-next-line no-magic-numbers
  getSimilarCars(carId: string, tenure: number, mileage: number, limit: number = 3): Observable<CarList> {
    return this._http
      .get<ApiResponse<any>>(`${this._carEndpoint}/vehicles/similar-cars/${carId}/${tenure}/${mileage}`, {
        params: { limit, },
      }).pipe(map((res) => res.data));
  }

  getCarDetails(id: string): Observable<ListedCar> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/vehicles/' + id)
      .pipe(map((res) => res.data));
  }

  getInventoryCarDetails(id: string): Observable<InventoryCar> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/inventory-vehicles/' + id)
      .pipe(map((res) => res.data));
  }

  getShortlisted(params: any): Observable<CarShortlisted> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/shortlisted/', { params: params, })
      .pipe(map((res) => res.data));
  }

  updateShortlisted(id: string): Observable<ListedCar> {
    return this._http
      .patch<ApiResponse<any>>(this._carEndpoint + '/shortlisted/update', {
        vehicle: id,
      })
      .pipe(map((res) => res.data));
  }

  getBrands(params: any): Observable<BrandList> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/brands/', { params, })
      .pipe(map((res) => res.data));
  }

  getModels(params: any): Observable<ModelList> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/models/', { params, })
      .pipe(map((res) => res.data));
  }

  getVariants(params: any): Observable<VariantList> {
    return this._http
      .get<ApiResponse<any>>(this._carEndpoint + '/variants/', { params, })
      .pipe(map((res) => res.data));
  }

  calculateLeaseRental(body: any): Observable<any> {
    return this._http
      .post<ApiResponse<any>>(this._carEndpoint + '/variant-pricing/calculate-slb-lease-rental', body)
      .pipe(map((res) => res.data));
  }
}