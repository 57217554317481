import { Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDisableBodyScroll]',
})
export class DisableBodyScrollDirective implements OnInit, OnDestroy {

  constructor(
    private _renderer: Renderer2
  ) { 
    
  }

  ngOnInit(): void {
    this._renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(document.body, 'modal-open');
  }

}
