import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faChevronRight, faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CarService } from 'src/app/core/services/car.service';
import { ListedCar, CarList } from 'src/app/core/models/car.model';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss',],
})
export class FilterDropdownComponent {

  faChevronRight = faChevronRight;
  faCaretDown = faCaretDown;
  faSearch = faSearch;

  isOpen: boolean = false;
  isSearching: boolean = false;
  listedVehicles: ListedCar[] = [];
  isListingPage: boolean = false;

  constructor(
    private _carService: CarService,
    private _router: Router,
    private _activateRoute: ActivatedRoute
  ) {
    this.isOnListingPage();
  }

  navigateTo(params: any) {
    this.isOpen = false;
    this._router.navigate(['cars',], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  isOnListingPage() {
    const lastUrl = this._router.url;
    this.isListingPage = (lastUrl?.startsWith('/cars') && !lastUrl?.startsWith('/cars/'))
      || lastUrl?.startsWith('/brand')
      || lastUrl?.startsWith('/model')
      || lastUrl?.startsWith('/curated')
      || false;
  }

  onSearchFocus(isFocus: boolean) {
    this.isSearching = isFocus;
    this.isOnListingPage();
  }

  searchVehicle(inputEvent: any) {
    const element = inputEvent.target as HTMLInputElement;
    const oldParams: any = {
      ...this._activateRoute.snapshot.queryParams,
      search: element.value,
    };
    /**
     * On listing page add search to query params
     * & on other page show search result in drop down
     */
    if (this.isListingPage) {
      if (!oldParams['search']) delete oldParams['search'];
      this._router.navigate([], { queryParams: oldParams, });
      return;
    }
    // Do not search with empty string
    if (!element.value) {
      this.listedVehicles = [];
      return;
    }
    this._carService.getListedCars({ search: element.value, }).subscribe({
      next: (data: CarList) => {
        this.listedVehicles = data.vehicles;
      },
    });
  }

  onSearchResultClick(params: any) {
    this.isOpen = false;
    this.isSearching = false;
    if (params?.variant) {
      this._router.navigateByUrl(`cars/details/${params.slug}`); return;
    }
    this._router.navigate(['cars',], {
      queryParams: params,
      // QueryParamsHandling: 'merge'
    });
  }

}
