import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss',],
})
export class LogoComponent {

  // eslint-disable-next-line no-magic-numbers
  @Input() size: number = 20;

  constructor(private _router: Router) { }

  navigateTo(path: string) {
    this._router.navigateByUrl(path);
  }

}
