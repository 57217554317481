<div class="tw-group tw-cursor-pointer tw-max-w-[400px]">
  <div class="tw-flex">
    <div class="input-group tw-w-full tw-rounded-bl-md tw-rounded-tl-md tw-border tw-border-grayUltraLight">
      <input type="text" (input)="searchVehicle($event)" (focus)="onSearchFocus(true)" class="form-control tw-border-0"
        style="padding-left: 10px!important; font-size: small;" placeholder="Search by brand, model & variant">
    </div>
    <button class="tw-rounded-br-md tw-rounded-tr-md" aria-label="Search PumPumPum"
      style="background-color: #FCEEC3; width: 40px;">
      <fa-icon [icon]="faSearch" class="tw-text-pinkDark"></fa-icon>
    </button>
  </div>
  <ul
    class="tw-absolute tw-z-50 tw-shadow-lg tw-top-[53px] tw-w-full tw-max-w-[400px] tw-hidden tw-bg-white group-hover:tw-flex">
    <app-search-result (itemClick)="onSearchResultClick($event)" [cars]="listedVehicles"
      *ngIf="!isListingPage && isSearching && listedVehicles.length"></app-search-result>
  </ul>
</div>