<div class="search-result tw-text-sm">
  <ul class="row m-0">
    <li class="col-12"><b>VARIANTS</b></li>
    <li class="d-flex align-items-center" *ngFor="let veh of cars;" (click)="selectVariant(veh.variant)">
      <p class="mb-0">{{veh.brand.name + '-' + veh.model.name + '-' + veh.variant.name | titlecase}}</p>
      <span class="spacer"></span>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </li>
  </ul>
  <ul class="row m-0" *ngIf="brands.length">
    <li class="col-12"><b>BRANDS</b></li>
    <li class="col-6 d-flex align-items-center" *ngFor="let brand of brands;" (click)="selectBrand(brand)">
      <p class="mb-0">{{brand | titlecase}}</p>
      <span class="spacer"></span>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </li>
  </ul>
  <ul class="row m-0" *ngIf="models.length">
    <li class="col-12"><b>MODELS</b></li>
    <li class="col-6 d-flex align-items-center" *ngFor="let model of models;" (click)="selectModel(model)">
      <p class="mb-0">{{model | titlecase}}</p>
      <span class="spacer"></span>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </li>
  </ul>
</div>