import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../constants';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  _authEndpoint = `${environment.apiBaseUrl}/user/v1/auth`;

  constructor(private _http: HttpClient) { }

  /**
   * @param <token>
   * @description Store user token in local storage for authenticate logged in user
   */
  setTokenInLocalStorage(token: string): void {
    localStorage.setItem(AppConstants.AUTH_TOKEN_KEY, JSON.stringify(token));
  }

  setUserInLocalStorage(user: User) {
    localStorage.setItem(AppConstants.AUTH_USER_KEY, JSON.stringify(user));
  }

  /**
   * @returns token
   */
  getToken() {
    const tokenString = localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
    return tokenString ? JSON.parse(tokenString) : null;
  }

  getCurrentUser(): User | undefined {
    const userString = localStorage.getItem(AppConstants.AUTH_USER_KEY);
    // Temp prev AUTH_USER_KEY data was set to "undefined"
    if (userString == 'undefined') return undefined;
    return userString ? JSON.parse(userString) as User : undefined;
  }

  sendOtp(data: any): Observable<any> {
    return this._http.post<ApiResponse<any>>(this._authEndpoint + '/send-otp', data)
      .pipe(map((res) => res.data));
  }

  verifyOtp(data: any): Observable<any> {
    return this._http.post<ApiResponse<any>>(this._authEndpoint + '/verify-otp', data)
      .pipe(map((res) => res.data));
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      localStorage.clear();
      setTimeout(() => {
        observer.next(true);
        // eslint-disable-next-line no-magic-numbers
      }, 100);
    });
  }

  signup(data: any): Observable<any> {
    return this._http.post<ApiResponse<any>>(this._authEndpoint, data)
      .pipe(map((res) => res.data));
  }

}