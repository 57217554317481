<app-header></app-header>
<div class="main-container">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<!-- <div [ngClass]="scrollingUp ? 'tw-bottom-0': '-tw-bottom-32'" class="tw-duration-700 tw-ease-in-out tw-fixed t-w-20 tw-z-50 tw-right-0"> -->
<div class="tw-fixed tw-z-50 tw-bottom-20 xl:tw-bottom-5 tw-right-5 xl:tw-right-20 2xl:tw-right-32" *ngIf="showWhatsappIcon">
  <a aria-label="Connect PumPumPum on WhatsApp" target="_blank" href="https://wa.me/message/DGZXLXADILGOD1" class="tw-flex-shrink-0">
    <img class="tw-w-14 md:tw-w-20" src="./assets/images/whatsappicon.svg" alt="">
  </a>
</div>