import { Component, HostListener } from '@angular/core';
import { faArrowLeft, faHome, faUser, faQuestionCircle, faHeart } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstants, navUrl } from 'src/app/core/constants';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { Subscription } from 'src/app/core/models/user.model';

interface Tab {
  icon: any;
  label: string;
  isActive: boolean;
  path: string;
  isVisible: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',],
})
export class DashboardComponent {

  faArrowLeft = faArrowLeft;

  isSmallScreen = false;

  // isSubscription: boolean = false;

  tabs: Tab[] = [
    {
      icon: faHome,
      label: 'Dashboard',
      isActive: true,
      path: navUrl.DASHBOARD,
      isVisible: true,
    },
    {
      icon: faUser,
      label: 'Profile',
      isActive: false,
      path: navUrl.PROFILE,
      isVisible: true,

    },
    // {
    //   icon: faWallet,
    //   label: 'Payments',
    //   isActive: false,
    //   path: navUrl.PAYMENTS,
    //   isVisible: true,

    // },
    {
      icon: faHeart,
      label: 'Wishlist',
      isActive: false,
      path: navUrl.WISHLIST,
      isVisible: true,
    },
    // {
    //   icon: faFileAlt,
    //   label: 'DOCUMENTS',
    //   isActive: false,
    //   path: '/documents',
    // },

    // {
    //   icon: faCog,
    //   label: 'Settings',
    //   isActive: false,
    //   path: '/settings',
    //   isVisible: true,
    // },
    {
      icon: faQuestionCircle,
      label: 'Support',
      isActive: false,
      path: navUrl.SUPPORT,
      isVisible: false,
    },
  ];
  routeTitle: string = 'Dashboard';

  constructor(
    private _location: Location,
    private _router: Router,
    private _subscriptionService: SubscriptionService
  ) {
    this._router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          const path = event.url.split('/').pop();
          if (path) this.routeTitle = path.split('-').join(' ');
          this.checkIfSmallScreen();
        }
      },
    });
    this.loadSubscription();
  }

  @HostListener('window:resize', ['$event',])
  onResize(_event: Event) {
    this.checkIfSmallScreen();
  }

  checkIfSmallScreen() {
    this.isSmallScreen = window.innerWidth < AppConstants.SMALL_SCREEN;
  }

  navigateBack() {
    if (this._router.url === '/dashboard') {
      this._router.navigateByUrl('');
    } else {
      this._location.back();
    }
  }

  loadSubscription() {
    this._subscriptionService.getSubscription().subscribe({
      next: (res: any) => {
        const subscription = res.subscriptions;
        const supportTab = this.tabs.find(t => t.label === 'Support');
        supportTab!.isVisible = subscription.some((subs: Subscription) => subs.status === 'ACTIVE');
      },
    });
  }

  navigateTo(path: string, index: number) {
    this.tabs.map((tab: Tab, i: number) => {
      if (index == i) tab.isActive = true;
      else tab.isActive = false;
    });
    this._router.navigateByUrl(`dashboard${path}`);
  }
}
