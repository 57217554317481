import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { SEOService } from 'src/app/core/services/seo.service';
import { fromEvent } from 'rxjs';
import { convertUrlToStr } from 'src/app/core/utils';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';

const SEO_METADATA = {
  ncr: {
    title: '[White Plate] Car Subscription & Lease in Delhi NCR, Gurgaon, Noida - PumPumPum',
    description: 'PumPumPum: Pre-owned monthly car subscription & lease service in Delhi, Noida, Gurgaon & entire NCR ✯ Zero downpayment ✯ White plate car ✯ Free maintenance & insurance',
  },
  bangalore: {
    title: '[White Plate] Monthly Car Subscription & Lease in Bangalore - PumPumPum',
    description: 'PumPumPum: Affordable & flexible pre-owned monthly car subscription & lease service in Bangalore ✯ Zero downpayment ✯ White plate car ✯ Free maintenance & insurance',
  },
  hyderabad: {
    title: '[White Plate] Monthly Car Subscription & Lease in Hyderabad - PumPumPum',
    description: 'PumPumPum: Affordable & flexible pre-owned monthly car subscription & lease service in Hyderabad ✯ Zero downpayment ✯ White plate car ✯ Free maintenance & insurance',
  },
  brand: {
    title: 'Used {{brand}} Car Subscription Plan and Lease Deals - PumPumPum',
    description: 'PumPumPum: Used {{brand}} cars monthly subscription & lease service in Bangalore, Delhi NCR & Hyderabad ✯ Zero downpayment ✯ White plate car ✯ Free maintenance & insurance',
  },
  model: {
    title: 'Used {{model}} Car Subscription Plan and Lease Deals - PumPumPum',
    description: 'PumPumPum: Used {{model}} monthly subscription & lease service in Bangalore, Delhi NCR & Hyderabad ✯ Zero downpayment ✯ White plate car ✯ Free maintenance & insurance',
  },
  curated: {
    title: 'Subscribe or Lease Used {{curated}} Cars in Delhi NCR, Bangalore, Hyderabad - PumPumPum',
    description: 'PumPumPum: Used {{curated}} cars monthly subscription and lease service in Bangalore, Delhi NCR and Hyderabad ✯ Zero downpayment ✯ White plate car ✯ Free maintenance and insurance',
  },
  default: {
    title: '[₹0 Downpayment] Used Car Subscription Service in India - PumPumPum',
    description: 'PumPumPum: Affordable & flexible pre-owned monthly car subscription service in India ✯ Zero Downpayment ✯ White Plate Car ✯ Free maintenance & insurance',
  },
};


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss',],
  providers: [TitleCasePipe,],
})
export class MainComponent implements OnInit {

  // eslint-disable-next-line no-magic-numbers
  startPosition: number = 0;
  scrollingUp: boolean = true;
  showWhatsappIcon: boolean = true;

  constructor(
    private _router: Router,
    private _seoService: SEOService,
    private _activatedRoute: ActivatedRoute,
    private _titleCasePipe: TitleCasePipe
  ) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe({
        next: (event: any) => {
          const { url, } = event;
          // Update title and description
          this._updateMetadata(url);
          if (url.startsWith('/cars') || url.startsWith('/how-pumpumpum-works')) {
            this._seoService.setNoIndex();
          }

          this.showWhatsappIcon = !url.startsWith('/corporate-lease');
          this.showWhatsappIcon = !url.startsWith('/ppp-invest');

          // Update canonical link
          const canonicalLink = `${environment.domain}${url != '/' ? url : ''}`;
          this._seoService.setCanonicalLink(canonicalLink);
        },
      });
  }

  ngOnInit(): void {
    fromEvent(window, 'scroll').subscribe(() => {
      const currentPosition = scrollY;
      this.scrollingUp = this.startPosition > currentPosition;
      this.startPosition = currentPosition;
    });
  }

  _updateMetadata(URL: string) {
    let title, description;
    if (URL.startsWith('/city/')) {
      if (URL.includes('ncr')) {
        title = SEO_METADATA['ncr'].title;
        description = SEO_METADATA['ncr'].description;
      } else if (URL.includes('bangalore')) {
        title = SEO_METADATA['bangalore'].title;
        description = SEO_METADATA['bangalore'].description;
      } else if (URL.includes('hyderabad')) {
        title = SEO_METADATA['hyderabad'].title;
        description = SEO_METADATA['hyderabad'].description;
      }
    } else if (URL.startsWith('/brand/')) {
      // eslint-disable-next-line no-magic-numbers
      const brandStr = convertUrlToStr(URL.split('brand/').pop()?.split('-car')?.[0]);
      const brand = this._titleCasePipe.transform(brandStr);
      title = SEO_METADATA['brand'].title.replace('{{brand}}', brand);
      description = SEO_METADATA['brand'].description.replace('{{brand}}', brand);
    } else if (URL.startsWith('/model/')) {
      // eslint-disable-next-line no-magic-numbers
      const modelStr = convertUrlToStr(URL.split('model/').pop()?.split('-car')?.[0]);
      const model = this._titleCasePipe.transform(modelStr);
      title = SEO_METADATA['model'].title.replace('{{model}}', model);
      description = SEO_METADATA['model'].description.replace('{{model}}', model);
    } else if (URL.startsWith('/curated/')) {
      // eslint-disable-next-line no-magic-numbers
      const curatedStr = convertUrlToStr(URL.split('curated/').pop()?.split('-car')?.[0]);
      const curated = this._titleCasePipe.transform(curatedStr);
      title = SEO_METADATA['curated'].title.replace('{{curated}}', curated);
      description = SEO_METADATA['curated'].description.replace('{{curated}}', curated);
    } else {
      if (URL === '/' || URL.startsWith('/cars')) {
        title = SEO_METADATA['default'].title;
        description = SEO_METADATA['default'].description;
      } else {
        // eslint-disable-next-line no-magic-numbers
        const { title: mTitle, description: mDescription, } = this._activatedRoute.snapshot.children[0].data;
        if (mTitle) title = mTitle;
        if (mDescription) mDescription;
      }
    }
    if (title) this._seoService.updateTitle(title);
    if (description) this._seoService.updateDescription(description);
  }
}
