import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListedCar, CarList } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';

@Component({
  selector: 'app-search-mobile',
  templateUrl: './search-mobile.component.html',
  styleUrls: ['./search-mobile.component.scss',],
})
export class SearchMobileComponent {
  faSearch = faSearch;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faTimes = faTimes;


  showSearchView = false;

  listedVehicles: ListedCar[] = [];

  constructor(
    private _router: Router,
    private _carService: CarService
  ) { }

  toggleSearchView() {
    this.showSearchView = !this.showSearchView;
  }

  searchVehicle(inputEvent: any) {
    const element = inputEvent.target as HTMLInputElement;
    if(!element.value) {
      this.listedVehicles = [];
      return;
    }
    this._carService.getListedCars({ search: element.value, }).subscribe({
      next: (data: CarList) => {
        this.listedVehicles = data.vehicles;
      },
    });
  }

  onSearchResultClick(params: any) {
    this.toggleSearchView();
    this._router.navigate(['cars',], {
      queryParams: params,
      // QueryParamsHandling: 'merge'
    });
  }

}
