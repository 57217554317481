import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { TokenInterceptor } from './core/interceptor/token.Interceptor';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { MainComponent } from './modules/main/main.component';
import { NotFoundComponent } from './modules/shared/layout/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () => import('./modules/main/main.module').then(main => main.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(auth => auth.AuthModule),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(dashboard => dashboard.DashboardModule),
    canActivate: [AuthGuard,],
  },
  {
    path: 'kyc',
    loadChildren: () => import('./modules/kyc/kyc.module').then(kyc => kyc.KycModule),
    canActivate: [AuthGuard,],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
  }),],
  exports: [RouterModule,],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class AppRoutingModule { }
