import { Component, Optional, PLATFORM_ID, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isPlatformServer } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Title } from '@angular/platform-browser';

interface PartialResponse {
  status(_code: number): this;
}

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss',],
})
export class NotFoundComponent implements OnInit {

  faArrowRight = faArrowRight;

  constructor(
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Optional() @Inject(RESPONSE) private _response: PartialResponse,
    private _titleService: Title
  ) {
    if (isPlatformServer(this._platformId) && this._response) {
      // eslint-disable-next-line no-magic-numbers
      this._response.status(404);
    }
  }

  ngOnInit(): void {
    this._titleService.setTitle('Page Not Found');
  }

  navigateTo() {
    this._router.navigate(['',], {
      queryParams: {},
      queryParamsHandling: 'merge',
    });
  }
}
