import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-nitro-x',
  template: '',
})
export class NitroXComponent {

  private loadedScripts: Set<string> = new Set<string>();

  constructor(
    private _router: Router,
    @Inject(PLATFORM_ID) private readonly _platformId: Object,
    @Inject(DOCUMENT) private _doc: Document
  ) {

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!environment.production) return;
        if (event.url === '/corporate-lease' && !this.loadedScripts.has('CORPORATE')) {
          this.loadedScripts.add('CORPORATE');
          const scriptNitroX = this._doc.createElement('script') as HTMLScriptElement;
          scriptNitroX.type = 'text/javascript';
          scriptNitroX.text = `
          (function(n,i,t,r,o) {
            var a,m;n['NitroObject']=o;n[o]=n[o]||function(){
            (n[o].q=n[o].q||[]).push(arguments)},n[o].l=1*new Date();n[o].h=r;a=i.createElement(t),
            m=i.getElementsByTagName(t)[0];a.async=1;a.src=r;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://x.getnitro.co/nitro.js','nitro');
            nitro('configure', '6ef74e2a-9266-4099-ac44-7e6604c8cc1d');
          `;
          this._doc.head.appendChild(scriptNitroX);
          return;
        }
        if (this.loadedScripts.has('RETAIL')) return;
        this.loadedScripts.add('RETAIL');
        const scriptNitroX = this._doc.createElement('script') as HTMLScriptElement;
        scriptNitroX.type = 'text/javascript';
        scriptNitroX.text = `
        (function(n,i,t,r,o) {
          var a,m;n['NitroObject']=o;n[o]=n[o]||function(){
          (n[o].q=n[o].q||[]).push(arguments)},n[o].l=1*new Date();n[o].h=r;a=i.createElement(t),
          m=i.getElementsByTagName(t)[0];a.async=1;a.src=r;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://x.getnitro.co/nitro.js','nitro');
          nitro('configure', '65d68b9b-20d3-4681-abf2-039fe0878bce');
        `;
        this._doc.head.appendChild(scriptNitroX);
      }
    });
  }
}