/* eslint-disable no-magic-numbers */
export class AppConstants {
  static readonly AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
  static readonly AUTH_USER_KEY = 'AUTH_USER_KEY';

  // Validator Regex
  static readonly PHONE_PATTERN = /^[6789]{1}[0-9]{9}$/;
  // Tslint:disable-next-line:max-line-length
  static readonly EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly OTP_PATTERN = /\b\d{4}\b/;
  static readonly EXPERIAN_OTP_PATTERN = /\b\d{6}\b/;
  static readonly PINCODE_PATTERN = /\b\d{6}\b/;
  static readonly PAN_PATTERN = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  static readonly DL_PATTERN = /^[A-Z]{2}[0-9]{13,14}$\b/;
  static readonly AADHAR_PATTERN = /^[0-9 ]{12}$\b/;
  static readonly PER_PAGE = 19;
  static readonly CURRENT_CITY_KEY = 'CURRENT_CITY_KEY';
  static readonly SHORTLISTED_CARS_KEY = 'SHORTLISTED_CARS_KEY';
  static readonly SELECTED_CAR_KEY = 'SELECTED_CAR_KEY';
  static readonly ZERO = 0;
  static readonly NEGATIVE_ONE = -1;
  static readonly ONE = 1;
  static readonly NAV_WIDTH = 100;
  static readonly DEFAULT_OTP_LENGTH = 4;
  static readonly AADHAR_OTP_LENGTH = 6;
  static readonly SMALL_SCREEN = 768;
  static readonly CREDIT_CONSENT_VALIDITY = 6;
  // MB
  static readonly MAX_FILE_SIZE = 200;

  static readonly MIN_CREDIT_LIMIT = 700;

  // Google Analytics
  static readonly GOOGLE_A_ID = 'G-NQMVHYW46X';
  // Google Tag Manager
  static readonly GOOGLE_GTM_ID = 'GTM-PNSM99K';
  // Google AD
  static readonly GOOGLE_AD_ID = 'AW-668308680';
}

export enum AddressType {
  CURRENT_ADDRESS = 'CURRENT_ADDRESS',
  PERMANENT_ADDRESS = 'PERMANENT_ADDRESS',
}

export enum navUrl {
  DASHBOARD = '',
  PROFILE = '/profile',
  PAYMENTS = '/payments',
  WISHLIST = '/shortlisted-cars',
  SUPPORT = '/get-support'
}

export const ONBOARDING_STEPS: { [key: string]: string } = {
  SIGNUP: 'signup',
  VEHICLE_SELECTION: 'vehicle-selection',
  PERSONAL_DETAILS: 'personal-info',
  WORK_INFO: 'work-info',
  CONTACT_DETAILS: 'contact-details',
  ADDITIONAL_DETAILS: 'additional-info',
  BANK_STATEMENT: 'bank-statement',
  REVIEW_SOFT_ELIGIBILITY: 'bank-statement-status',
  CREDIT_SCORE: 'credit-score',
  ELIGIBILITY: 'kyc-status',
  TOKEN_AMOUNT: 'pay-token',
  CURRENT_ADDRESS: 'current-address',
  PERMANENT_ADDRESS: 'permanent-address',
  FIELD_INSPECTION: 'application-submitted',
  ADDITIONAL_DOCUMENTS: 'additional-documents',
  SECURITY_AMOUNT: 'pay-security',
  ESTIMATED_DELIVERY: 'kyc-completed',
  ADVANCE_FEE: 'pay-advance',
  AUTO_DEBIT: 'auto-debit',
  VEHICLE_READY: 'ready-to-deliver',
  HR_APPROVAL: 'hr-approval',
  QUOTATION_SIGNED: 'hr-approval',
  SALE_LETTER: 'sale-letter',
  SALE_DOCUMENTS: 'sale-documents',
  PRODUCT_SELECTION: 'product-selection',
  PRE_SALE: 'pre-sale',
  ADDENDUM: 'sign-addendum',
};