import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss',],
})
export class TabButtonComponent {

  @Input() label!: string;
  @Input() icon!: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter();

  constructor() { }

  onButtonClick() {
    this.onClick.emit();
  }

}
