<app-header></app-header>
<div class="container tw-py-8 lg:tw-py-16">
    <div class="tw-text-lg tw-pb-2  md:tw-text-xl lg:tw-text-3xl tw-flex tw-items-center tw-font-bold  tw-w-full tw-justify-center tw-text-center tw-text-grayDark">
       There has been an accident!
    </div>
    <div class="tw-text-base tw-pb-2 md:tw-text-base lg:tw-text-xl tw-flex tw-items-center tw-font-bold  tw-w-full tw-justify-center tw-text-center tw-text-grayMid">
         404 Error
    </div>
    <div class="tw-w-full tw-flex tw-justify-center tw-items-center tw-py-8 tw-flex-col">

        <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/2 tw-mx-auto">
            <img src="../assets/illustrations/404.svg" class="tw-w-full   tw-object-contain">
        </div>
        <div class="tw-pt-8">
            <button (click)="navigateTo()" class="tw-bg-pinkDark  tw-cursor-pointer tw-text-white tw-px-4 tw-py-2 tw-rounded-md">
                Go To Home &nbsp;
                <fa-icon [icon]="faArrowRight"></fa-icon>
              </button>
        </div>
    </div>
</div>
<app-footer></app-footer>