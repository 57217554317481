<button aria-label="Toggle search view" class="tw-text-black d-flex align-items-center tw-w-full tw-border-grayLight" (click)="toggleSearchView()">
  <fa-icon [icon]="faSearch" class="tw-p-0 tw-m-0 px-2"></fa-icon>
</button>

<div appDisableBodyScroll class="search-view" *ngIf="showSearchView">
  <div class="d-flex p-3 shadow-sm">
    <button aria-label="Toggle search view" class="btn tw-pl-0" (click)="toggleSearchView()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <div class="input-group d-flex align-items-center tw-w-full">
      <fa-icon [icon]="faSearch" class="tw-p-0 tw-text-grayDark tw-m-0 px-2 "></fa-icon>
      <input appAutoFocus (input)="searchVehicle($event)" type="search"
        class="rounded-0 tw-grow tw-p-1.5 focus:tw-outline-none" placeholder="Search by model">
    </div>
  </div>
  <app-search-result (itemClick)="onSearchResultClick($event)" [cars]="listedVehicles"></app-search-result>
</div>