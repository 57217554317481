import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faUser, faBars, faArrowLeft, faPhone, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/core/services/auth.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { AppConstants } from 'src/app/core/constants';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss',],
})
export class HeaderComponent implements OnInit {

  faHeart = faHeart;
  faUser = faUser;
  faBars = faBars;
  faPhone = faPhone;
  faQuestion = faQuestion;
  smallScreen: boolean = true;
  fixedHeader: boolean = false;
  showBackButton: boolean = false;
  showHeader: boolean = true; // Used to hide header when pages view in app browser

  @ViewChild(SideNavComponent) sideNavComponent?: SideNavComponent;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _location: Location,
    private _route: ActivatedRoute
  ) {
    const { headless, } = this._route.snapshot.queryParams;
    if (headless != undefined) this.showHeader = headless != 'true';
  }

  ngOnInit(): void {
    this.isSmallScreen();

    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: (_event) => {
        this.isSmallScreen();
      },
    });
  }

  @HostListener('window:resize', ['$event',])
  onResize(_event: Event) {
    this.isSmallScreen();
  }

  @HostListener('window:scroll', ['$event',])
  onScroll(_event: Event) {
    this.fixedHeader = window.scrollY > AppConstants.ZERO;
  }

  isSmallScreen() {
    this.smallScreen = window.innerWidth < AppConstants.SMALL_SCREEN;
    // Show back button on small screen & car pages
    this.showBackButton = this._router.url.startsWith('/cars') && this.smallScreen;
    if (this.showBackButton) {
      this.faBars = faArrowLeft;
    } else {
      this.faBars = faBars;
    }
  }

  navigateTo(path: string) {
    this._router.navigateByUrl(path);
  }

  openLoginDialog() {
    if (this._authService.getToken()) {
      this._router.navigateByUrl('dashboard');
      return;
    }
    this._router.navigateByUrl('auth');
  }

  openSideNav() {
    if (this.showBackButton) {
      this._location.back();
    } else {
      this.sideNavComponent?.toggleNav();
    }
  }
}
